<template>
  <div class="flex-row">
    <div class="company-wrapper company-wrapper-skeleton" v-if="isLoading">
      <mu-circular-progress class="demo-circular-progress" :size="26"></mu-circular-progress>
    </div>
    <div class="company-wrapper" v-if="promoPage.id && !isLoading">
      <company-head-nav
          :leftNav="true"
          @closeItem="close()"
          @promoFavoriteCompanyEvent="toggleFavFromCompanyHeadNav"
          @promoGoToOwnerMessages="goToOwnerMessages"
          :isFavoriteCompany="isFavoriteCompany"
          :video_stream_url="promoPage.video_stream_url"
          :external_id="external_id"
          @enable-fullscreen-mode="enableFullscreenMode"
      ></company-head-nav>

      <div :class="{'overlay overlay-visible': fullscreenMode}">
        <div class="close-icon" @click="enableFullscreenMode(false)">
          <iconZoomOut :color="'#fff'"></iconZoomOut>
        </div>
        <company-media-block
          :external-id="promoPage && promoPage.external_id"
        ></company-media-block>
      </div>

      <div class="company">
        <div class="head-block">
          <div class="avatar">
            <img :src="setCompanyAvatar(promoPage.logo_url)" alt="image"/>
          </div>
          <div class="title">
            {{truncatedText(promoPage.title, 75)}}
            <div class="location" v-if="promoPage.country && promoPage.city">
              {{ promoPage.city.name }}, {{ promoPage.country.name }}
            </div>
            <div class="website" v-if="promoPage.website"><a :href="promoPage.website" target="_blank">{{promoPage.website}}</a></div>
          </div>
        </div>
        <div class="nav">
          <span class="tab cur-p" :class="{'highlight': navSelected === 'info'}"
                @click="navSelect('info')">{{$t('title.info')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'products'}"
                @click="navSelect('products')">{{$t('title.products')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'team'}"
                @click="navSelect('team')">{{$t('title.team')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'documents'}"
                @click="navSelect('documents')">{{$t('title.documents')}}</span>
          <span class="tab cur-p" :class="{'highlight': navSelected === 'notes'}"
                @click="navSelect('notes')">{{$t('title.notes')}}</span>
        </div>
      </div>
      <div class="field-block" v-if="navSelected === 'info'">
        <div class="field-header">
          <div class="title">{{$t('title.info')}}</div>
        </div>
        <div class="field-content">
          <div class="content">
            <div class="description ws-pre-wrap">
              <truncate action-class="read-more" v-if="this.promoPage.description"
                        :length="180"
                        :less="$t('button.less')"
                        :clamp="$t('button.more')"
                        :text="this.promoPage.description"></truncate>
            </div>
          </div>
        </div>
      </div>

      <!-- Product list -->
      <div class="field-block field-block-products"
           v-if="navSelected === 'info' || navSelected === 'products'"
           :class="{
              'field-block-products-short' : navSelected !== 'products',
              'field-block-products-full' : navSelected === 'products'
           }"
      >
        <div class="field-header">
          <div class="title">{{$t('title.products')}}</div>
          <div class="action" v-if="navSelected !== 'products'">
            <a class="btn cur-p" @click="showAllProducts()">{{
              $t('button.seeAll')}}</a>
          </div>
        </div>
        <div class="field-content">
          <div class="content">
            <products-list :products="promoPage.products"
                           :companyName="promoPage.title"
                           :showAll="navSelected === 'products'"
                           :class="{
              'products-list-short' : navSelected !== 'products',
              'products-list-full' : navSelected === 'products'
           }"
            ></products-list>
          </div>
        </div>
      </div>

      <!--    ----------------->
      <div class="field-block field-block-team" v-if="navSelected === 'info' || navSelected === 'team'">
        <div class="field-header">
          <div class="title">{{$t('title.team')}}</div>
          <div class="action" v-if="navSelected !== 'team'">
            <span class="btn cur-p" @click="showAllTeam()">{{
              $t('button.seeAll')}}</span>
          </div>
        </div>
        <div class="field-content enumerable show-all">
          <div class="content-item cur-p"
               v-for="(item, key) in promoPage.attached_contacts"
               :key="key"
               @click="showProfile(item.contact.id)"
               v-show="key < 4 || navSelected === 'team'"
          >
            <div class="avatar round-radius m-auto">
              <img
                  :src="setAvatar(item.contact.photo_url)"
                  alt="img"
              />
            </div>
            <div class="title">
              <span v-if="item.contact.name && item.contact.name !== ' '">{{ item.contact.name }} </span>
              <span v-if="item.contact.surname">{{ item.contact.surname }}</span>
              <span v-if="(!item.contact.name || item.contact.name === ' ') && !item.contact.surname"> {{$t('contacts.info.noname')}} </span>
            </div>
            <div class="subtitle" v-if="item.contact.company_position">{{item.contact.company_position}}</div>
          </div>
        </div>
      </div>

      <!-- Documents -->
      <document-list
          class="field-block field-block-documents"
          :class="{
            'show-action': navSelected === 'info',
            'show-all': navSelected === 'documents'
          }"
          :docs="promoPage.files"
          :showAll="navSelected === 'documents'"
          @seeAllDocuments="navSelect('documents')"
          v-if="promoPage.files && promoPage.files.length && (navSelected === 'info' || navSelected === 'documents')"
      ></document-list>


      <!-- Notes -->
      <notes-list
          class="field-block field-block-notes"
          v-if="this.noteList && this.noteList.length && (navSelected === 'info' || navSelected === 'notes')"
          :notes="this.noteList"
          :showAll="navSelected === 'notes'"
          :showActionButton="navSelected !== 'notes'"
          @seeAllNotes="navSelect('notes')"
      ></notes-list>


      <div class="field-block" v-if=" navSelected === 'messages'">
        <div class="field-content messages">
          <div class="content-text messages-text">
            <mu-text-field v-model="messageText"
                           class="text-input"
                           full-width
                           multi-line
                           :rows="5"
                           :placeholder="$t('title.type_your_message')"></mu-text-field>
            <mu-button class="send-btn"
                       small
                       round
                       @click="sendMessage()">{{$t('button.send')}}
            </mu-button>
          </div>
        </div>
      </div>


      <div class="field-block" v-if=" navSelected === 'messages'">
        <div class="field-content messages">
          <div class="content-text messages-text">
            <mu-text-field v-model="messageText"
                           class="text-input"
                           full-width
                           multi-line
                           :rows="5"
                           :placeholder="$t('title.type_your_message')"></mu-text-field>
            <mu-button class="send-btn"
                       small
                       round
                       @click="sendMessage()">{{$t('button.send')}}
            </mu-button>
          </div>
        </div>
      </div>
    </div>
    <router-view :setNavSelected="profileNavSelected" name="b"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import axios from 'axios';
import companyHeadNav from './company-head-nav'
import truncate from 'vue-truncate-collapsed';
// import companyMediaBlock from './companyMediaBlock';
import productsList from '../../products/productList';
import { FileNameHelper } from '@/helpers/filename.helper';
import DocumentList from '../documents/documentList';
import NotesList from '../notes/notesList';
import iconZoomOut from '@/_modules/icons/components/icon-zoom-out.vue';
// import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
// import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import Statistics from '@/services/statistics';
import CompanyMediaBlock from '@/_modules/promo/components/company-media-block/company-media-block.vue';

// const BROADCASTS_CHECK_INTERVAL = 5000;

const beforeRouteEnter = { to: null, from: null };

export default {
  name: "promo-company",
  components: {
    NotesList,
    DocumentList,
    // companyMediaBlock,
    companyHeadNav,
    truncate,
    productsList,
    iconZoomOut,
    CompanyMediaBlock,
  },
  computed: {
    ...mapState({
      promoOwnerId(state) {
        this.updateNotesList();

        this.owner_id = state.promoStore.promoOwnerId;
        this.setMessages();

        return state.promoStore.promoOwnerId;
      },
      promoOwnerContactId(state) {
        this.ownerContact_id = state.promoStore.promoOwnerContactId;
        this.profileNavSelected = 'messages';
        return state.promoStore.promoOwnerContactId;
      },
      promoPage(state) {
        this.isFavoriteCompany = state.promoStore.promoPage.is_favorite;
        return state.promoStore.promoPage;
      },
      attachedContacts: state => state.promoStore.promoPage.attached_contacts,
      noteList(state) {
        return state.noteStore.noteListPromoPage.List
      },
      promoPageListLoading: state => state.promoStore.promoPageListLoading
    }),
    ...mapGetters({
      messageListPromoPageAll: '_messageStore/messages',
    })
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    this.event_id = this.$route.params.eventId;
    this.external_id = this.$route.params.external_id;

    this.$store.dispatch('promoStore/getPromoPage', {external_id: this.external_id, event_id: this.event_id})

    // this._startBroadcastsCheck();
  },
  updated() {
    this.event_id = this.$route.params.eventId;
    this.external_id = this.$route.params.external_id;
  },
  mounted() {
    this.updateNotesList();
  },
  // beforeDestroy() {
  //   this._stopBroadcastsCheck();
  // },
  watch: {
    external_id: {
      immediate: true,
      handler() {
        Statistics.promopageView({
          eventId: this.event_id,
          externalId: this.external_id,
        }, beforeRouteEnter);
      },
    },

    '$route.params.external_id': {
      immediate: true,
      handler() {
        this.updateNotesList();
      }
    },
    promoOwnerContactId: {
      immediate: true,
      handler() {
        if (!this.contact_id) {
          this.profileNavSelected = 'messages';
          this.showProfile(this.promoOwnerContactId);
        }
      }
    },
    isShowTeam: {
      handler() {
        this.showTeam = this.isShowTeam;
      }
    },
    isShowDocuments: {
      handler() {
        this.showDocuments = this.isShowDocuments;
      }
    },
    isShowNotes: {
      handler() {
        this.showNotes = this.isShowNotes;
      }
    },
    isFavoriteCompany: {
      immediate: true,
      handler() {
        this.$emit('promoCallFavCompany', this.isFavoriteCompany)
      }
    },
  },
  props: {
    isShowTeam: {
      type: Boolean,
    },
    isShowDocuments: {
      type: Boolean,
    },
    isShowNotes: {
      type: Boolean,
    },
  },
  data() {
    return {
      list: null,
      noteFav: true,
      showMore: false,
      more: this.$t('title.documents'),
      showTeam: false,
      showProducts: false,
      showDocuments: false,
      showNotes: false,
      event_id: null,
      external_id: null,
      contact_id: null,
      company_index: null,
      isFavoriteCompany: false,
      showMoreId: null,
      text: '',
      owner_id: null,
      ownerContact_id: null,
      isOwner: false,
      messageText: '',
      navSelected: 'info',
      profileNavSelected: '',
      fullscreenMode: false,
      isLoading: false,
      // isBroadcastsCheckEnabled: true,
      // broadcasts: [],
    };
  },
  methods: {
    /* Prepares params
     * and dispatches callNoteListAllPromoPage
     */
    updateNotesList() {
      let payload = {};
      if (this.event_id) {
        payload.event_id = this.event_id;

        // Does not make sense without event_id
        if (this.$route.params.external_id) {
          payload.external_id = this.$route.params.external_id;
        }
      }
      if (payload.event_id) {
        this.$store.dispatch('noteStore/callNoteListAllPromoPage', payload);
      }
    },
    setAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-300x300.png');
      }
      return noPhoto;
    },
    setCompanyAvatar(url) {
      let noPhoto;
      if (url) {
        return url
      } else {
        noPhoto = require('../../../../assets/images/no-avatar-company-300x300.png');
      }
      return noPhoto;
    },
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    showAllTeam() {
      this.showTeam = !this.showTeam;
      this.navSelect('team')
    },
    showAllProducts() {
      this.showProducts = !this.showProducts;
      this.navSelect('products')
    },
    showAllDocuments() {
      this.showDocuments = !this.showDocuments;
      this.navSelect('documents')
    },
    showProfile(contact_id) {

      if (!contact_id) {return;}

      let payload = {
        event_id: this.event_id || this.$route.params.eventId,
        contact_id: contact_id
      };

      this.isLoading = true;
      this.$store.dispatch('promoStore/getProfileByContactId', payload).then(() => {
        this.$router.push({name: 'promo-profile', params: {contact_id: contact_id}}).catch(() => {
        });
        this.isLoading = false;
      })
    },
    async setMessages() {
      if (this.event_id && this.owner_id) {

        await this.$store.dispatch('_messageStore/callMessageList',
          {
            eventId: this.event_id,
            userId: this.owner_id,
          })

        if (this.messageListAll && this.messageListPromoPageAll.List) {
          this.messageList = this.messageListPromoPageAll.List
        }
      }
    },
    async sendMessage() {
      if (this.messageText && this.messageText.trim()) {
       await this.$store.dispatch('_messageStore/sendMessage',
          {
            eventId: this.event_id,
            userId: this.owner_id,
            text: this.messageText.trim()
          })

       await this.setMessages();
        this.messageText = '';
      }
    },
    close() {
      this.$router.push({name: 'promo-page-events-companies'}).catch(() => {
      });
    },
    toggleFavFromCompanyHeadNav(bool) {
      this.isFavoriteCompany = bool;
      let payload = {
        event_id: this.event_id,
        external_id: this.external_id
      };

      if (!bool) {
        this.$store.dispatch('promoStore/deleteFavPromoPage', payload);
      } else {
        this.$store.dispatch('promoStore/addFavPromoPage', payload);
      }
    },
    goToOwnerMessages() {
      this.profileNavSelected = 'messages';
      this.showProfile(this.promoOwnerContactId);
    },
    navSelect(value) {
      this.navSelected = value || 'team';
      window.scrollTo(null, 0);
    },
    truncatedText(text, length) {
      if (text) {
        if (!length || length < 0) {
          length = text.length;
        }
        if (text.length > length) {
          return `${text.substring(0, length)}...`
        }
        return text;
      }
    },
    enableFullscreenMode(bool) {
      this.fullscreenMode = bool;
    },
    // _stopBroadcastsCheck() {
    //   this.isBroadcastsCheckEnabled = false;
    // },
    // async _sleep(milliseconds) {
    //   await new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve();
    //     }, milliseconds);
    //   });
    // },
    // async _startBroadcastsCheck() {
    //   while (this.isBroadcastsCheckEnabled) {
    //     await this._broadcastsCheck();
    //   }
    // },
    // async _broadcastsCheck() {
    //   if (!this.attachedContacts || !this.attachedContacts.length) {
    //     this.broadcasts = [];
    //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //     return;
    //   }
    //
    //   const attachedContacts = this.attachedContacts;
    //   const eventId = Number(this.$route.params.eventId);
    //
    //   for (const attachedContact of attachedContacts) {
    //     const { contact } = attachedContact;
    //     const broadcastUrl = MeetingRoomsHelper.getBroadcastUrl({
    //       type: MeetingRoomType.BROADCAST,
    //       eventId: parseInt(this.$route.params.eventId, 10),
    //       contactId: contact.id
    //     });
    //
    //     let checkResult;
    //     try {
    //       checkResult = await axios({method: 'GET', url: broadcastUrl, timeout: 2000});
    //     } catch { /* do nothing */ }
    //
    //     if (!this.isBroadcastsCheckEnabled) {
    //       this.broadcasts = [];
    //       return;
    //     }
    //     if (attachedContacts !== this.attachedContacts || eventId !== Number(this.$route.params.eventId)) {
    //       /* something has changed */
    //       this.broadcasts = [];
    //       await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //       return;
    //     }
    //
    //     const broadcastUrlIndex = this.broadcasts.indexOf(broadcastUrl);
    //     if (checkResult) {
    //       if (broadcastUrlIndex < 0) {
    //         this.broadcasts.push(broadcastUrl);
    //       }
    //     } else {
    //       if (broadcastUrlIndex > -1) {
    //         this.broadcasts.splice(broadcastUrlIndex, 1);
    //       }
    //     }
    //     await this._sleep(BROADCASTS_CHECK_INTERVAL);
    //   }
    // },
  }
};
</script>

<style scoped lang="scss">

  .overlay {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.6s;
    padding: 200px;

    pointer-events: none;

    .company-mediablock {
      width: 100%;
    }

  }

  .overlay img {
    max-width: 90%;
    max-height: 90%;
    width: auto;
    height: auto;
    transform: scale(0.95);
    transition: transform 0.3s;
  }

  .overlay-visible {
    visibility: visible;
    outline: none;
    cursor: default;
    opacity: 1;
    pointer-events: all;
  }

  .overlay-visible img {
    transform: scale(1);
  }

  .close-icon {
    position: absolute;
    right: 20px;
    top: 100px;
    cursor: pointer;
  }

  .flex-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .company-wrapper {
    width: calc(100% - 410px); // AW-734 100% - ширина колонки с профилем - щель между колонками
    min-width: 392px; // AW-734
    max-width: 870px; // AW-850
    height: auto;
    box-shadow: 5px 5px 15px #3754aa1a;
    border-radius: 25px;
    background-color: $biletum-white;
    z-index: 1;
    margin-right: 18px;
  }

  .truncated-text {
    height: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .company {
    padding: 0 25px;

    .head-block {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .avatar {
        width: 56px;
        height: 56px;
        min-width: 56px;
        border-radius: 5px;
        opacity: 1;
        position: relative;
        overflow: hidden;
        border: 1px solid #eaeaea;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% 50%;
        }
      }

      .title {
        text-align: left;
        font-size: 15px;
        font-family: Point, sans-serif;
        font-weight: bold;
        letter-spacing: 0;
        color: $biletum-black;
        margin-left: 20px;
        word-break: break-word;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .location {
        font-size: 1.1rem;
        font-weight: normal;
      }

      .website {
        font-size: 1.1rem;
        font-weight: normal;
        word-break: break-word;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: $biletum-light-blue;
        }
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      padding: 32px 0;

      .tab {
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: $biletum-black;
        text-decoration: none;
        opacity: 0.8;
      }

      .highlight {
        border-bottom: 1px solid $biletum-light-blue;
        color: $biletum-light-blue;
      }
    }

    .content {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .description {
        font-family: Point, sans-serif;
        font-size: 14px;
        text-align: left;
        word-wrap: break-word;
      }
    }
  }

  html[lang=ru] .nav a {
    font-size: 12px;
  }

  .field-block {
    padding: 10px 25px;
    position: relative;
    overflow: hidden;

    .field-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 8px;

      .title {
        text-align: left;
        font-family: Point, sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0;
        color: $biletum-black;
        opacity: 0.8;
        position: relative;

        &::before {
          display: block;
          position: absolute;
          left: -25px;
          bottom: 2px;
          content: "";
          border-bottom: 1px solid #eaeaea;
          width: 25px; /* Have to use precise units because we have overflow: visible on some field-blocks */
        }
      }

      .btn {
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 13px;
        color: #00b6f8;
        display: block;

        ::first-letter {
          text-transform: uppercase;
        }
      }
    }

    .enumerable {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row wrap;
      overflow: hidden;
      height: 140px;
    }

    .flex-column-center {
      display: flex;
      justify-content: center;
      flex-flow: column wrap;
      align-items: center;
    }

    .messages {
      .messages-text {
        display: flex;
        flex-flow: column wrap;
      }

      .text-input {
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-family: Point, sans-serif;
        font-size: 12px;
        letter-spacing: 0;
      }

      .send-btn {
        background-color: #00b6fb;
        color: $biletum-white;
        width: 120px;
        height: 22px;
        margin-left: auto;
      }

      .subtitle {
        text-align: center;
      }

      .date_text {
        text-align: center;
        font-size: 8px;
        font-family: Point, sans-serif;
        letter-spacing: 0;
        color: #000;
        opacity: 0.35;
        margin-bottom: 20px;
      }

      .text_title {
        font-size: 8px;
        font-family: Point, sans-serif;
        margin-bottom: 6px;
      }

      .text {
        font-size: 12px;
        font-family: Point, sans-serif;
      }
    }

    .field-content {
      padding: 16px 0;

      .square-radius {
        border-radius: 5px;
      }

      .round-radius {
        border-radius: 50%;
      }

      .content-item {
        margin-bottom: 26px;
        text-align: left;
        width: 25%;
        word-wrap: break-word;
        min-height: 120px;

        &:hover {
          background-color: darken($biletum-light-grey, 1%);
        }

      }

      .description {
        word-wrap: break-word;
      }

      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title {
        margin-top: 6px;
        max-width: 70px;
        margin-left: auto;
        margin-right: auto;
      }

      .title,
      .subtitle {
        text-align: center;
        font-family: Point, sans-serif;
        font-weight: 300;
        font-size: 10px;
        color: $biletum-black;
        opacity: 0.8;
      }
    }
  }

  .show-all {
    height: auto !important;
  }

  .m-auto {
    margin: auto;
  }

  .field-block-team {
    .field-content {
      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
      }

      .title,
      .subtitle {
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .company-wrapper-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
</style>

<style lang="scss">

  .read-more {
    font-family: Point, sans-serif;
    color: #00b6f8 !important;
    text-decoration: none;
    cursor: pointer;
  }

  .more-btn {
    text-align: left;
    font-family: Point, sans-serif;
    font-size: 8px;
    color: #00b6f8 !important;
  }
</style>
